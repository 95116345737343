.card {
  margin-bottom: 30px;
}

.card-img-top {
  object-fit: cover;
  height: 250px;
}

.card-title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 18px;
  margin-bottom: 15px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}
/* .card-container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 300px;
  height: 400px;
  margin: 10px;
}
.club-picture {
  width: 272px;
  height: 250px;
} */

@media (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    max-width: 100%;
  }
}
