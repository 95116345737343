/*ClubLandingPage.module.css*/

.card {
  border: none;

  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.card img {
  height: 300px;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
  margin: 10px; /* adjust as needed */
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.card-footer {
  background-color: #f8f9fa;
  border-top: none;
  padding: 10px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

h5 {
  margin-bottom: 10px;
}

.link {
  color: blue; /* Change the color to suit your design */
  text-decoration: underline; /* Add an underline to indicate a link */
  cursor: pointer; /* Make the cursor change to a hand on hover */
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* optional */
}
/* adjust card size for smaller screens */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: left;
  }
  .card {
    width: 100%; /* or whatever width you want */
    height: auto; /* let height adjust to content */
  }
}

/* adjust card size for even smaller screens */
@media (max-width: 480px) {
  .card {
    width: 100%; /* full container width */
  }
}

.event-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.event-card {
  background-color: #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  width: calc(33.33% - 10px);
}

.event-link:hover {
  text-decoration: none;
}

.event-header {
  padding: 10px;
}

.event-details {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.event-card-item {
  /* background-color: white; */
  border-radius: 1px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  flex-grow: 1;
  padding: 10px;
}

.event-button-item {
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 10px;
}

@media (max-width: 768px) {
  .event-card {
    width: calc(50% - 10px);
  }
}
