/* Style the event cards */
.card {
  margin-bottom: 2rem;
}

/* Make the attendee list more compact */
ul {
  padding-left: 1.25rem;
  list-style-type: none;
  margin-bottom: 0;
}

li {
  margin-bottom: 0.5rem;
}

/* Add some padding to the container */
.container {
  padding: 2rem;
}

/* Use media queries to make the page responsive */
@media only screen and (max-width: 768px) {
  /* Reduce font size for smaller screens */
  h1 {
    font-size: 2rem;
  }

  /* Adjust card padding for smaller screens */
  .card {
    padding: 1rem;
  }
}
