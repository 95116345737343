.responsive-image {
  max-width: 50%;
  height: auto;
}
/* supersecretpartypage.css */

/* Set background color and text color for the entire page */
body {
  background-color: #f5f5f5;
  color: #333;
}

/* Style the heading */
h1 {
  color: #ff6f00;
  font-size: 28px;
  margin-bottom: 16px;
}

/* Style the paragraph */
p {
  line-height: 1.5;
  margin-bottom: 12px;
}

/* Style the image */
.responsive-image {
  max-width: 50%;
  height: auto;
  margin: 24px 0;
}

/* Style the address container */
.address-container {
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

/* Style the address heading */
.address-container h2 {
  color: #ff6f00;
  font-size: 20px;
  margin-bottom: 12px;
}

/* Style the address lines */
.address-container p {
  margin-bottom: 6px;
}

/* supersecretpartypage.css */

/* Rest of the CSS code */

/* Style the link */
.address-container a {
  color: #ff6f00;
  text-decoration: none;
  font-size: 40px; /* Increase font size for link */
  display: inline-block; /* Add block-level behavior to link */
  padding: 8px 12px; /* Add padding around the link */
  border-radius: 4px; /* Add rounded corners to the link */
  color: #4625ec; /* Set text color for link */
}

.address-container a:hover {
  background-color: #ff9933; /* Change background color on hover */
}

.colorful {
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorful a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 30px;
}

.colorful a:first-child {
  background-color: #e1306c;
  margin-right: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.colorful a:last-child {
  background-color: #00a1df;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.colorful a:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.colorful a:first-child:hover {
  background-color: #b4004e;
}

.colorful a:last-child:hover {
  background-color: #0088c7;
}
