/* Center the form */
.event-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Set a max-width for the form on larger screens */
@media (min-width: 768px) {
  .event-form {
    max-width: 600px;
    margin: 0 auto;
  }
}

/* Style the form fields and labels */
.form-group {
  margin-bottom: 1.5rem;
}

label {
  font-weight: bold;
}

textarea {
  resize: none;
}

/* Style the submit button */
button[type="submit"] {
  background-color: #007bff; /* Blue */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: all 0.2s;
}

button[type="submit"]:hover {
  cursor: pointer;
  background-color: #0069d9; /* Dark blue */
}

button[type="submit"]:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

/* Style form validation errors */
.invalid-feedback {
  display: block;
  margin-top: 0.25rem;
  color: #dc3545;
}
