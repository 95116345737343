.navbar {
  font-size: 1.5rem;
}

.navbar-brand {
  font-size: 1.5rem;
}

.dropdown-font {
  font-size: 1.2rem;
}
