/* Set global font properties */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f4f4f4;
}

a {
  text-decoration: none;
  color: #333;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  float: right;
}

/* Add responsive styles for smaller screens */
@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  li {
    padding: 5px;
  }

  button {
    font-size: 14px;
    padding: 3px 6px;
  }
}

.white-link {
  color: #f4f4f4;
}
